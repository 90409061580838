import { Axios } from 'axios';
import { CreateUserReminderRequestBody, MutationComposedSKProps, UserBankAccount, UserExpense, UserExpenseMetrics, UserPayIn, UserReminder } from '../types/User';
import { StringOrNumber } from '../types/global';
import { financesApiAxios } from './axios-instances';
import { axiosRequestHandler } from './request-util';

export class FinancesApiClient {

  financesApiAxios: Axios;
  private jwt: string;

  constructor(private userId?: string) {
    this.financesApiAxios = financesApiAxios;
    this.jwt = '';
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  setUserJwt(jwt: string) {
    this.jwt = jwt;
  }

  private preRequestVerify() {
    if (!this.userId) throw new Error(`${this.constructor.name} does not have a 'userId' set.`);
  }

  private getRequestConf() {
    return { headers: { 'Authorization': `Bearer ${this.jwt}` } };
  }

  async getExpenseTypes() {
    this.preRequestVerify();
    return await axiosRequestHandler<string[]>(
      () => this.financesApiAxios.get(`/v1/${this.userId}/expense-types`,  this.getRequestConf())
    );
  }

  async getExpenses(dateRange?: [StringOrNumber, StringOrNumber]) {
    let queryParams = '';
    if (dateRange?.length === 2) {
      queryParams = `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
    }

    this.preRequestVerify();
    return await axiosRequestHandler<UserExpenseMetrics>(
      () => this.financesApiAxios.get(`/v1/${this.userId}/expenses${queryParams}`,  this.getRequestConf())
    );
  }

  async createExpense(expense: UserExpense) {
    this.preRequestVerify();
    return await axiosRequestHandler<void>(
      () => this.financesApiAxios.post(`/v1/${this.userId}/expenses`, expense, this.getRequestConf())
    );
  }

  async deleteExpense(createDate: number) {
    this.preRequestVerify();
    return await axiosRequestHandler<void>(
      () => this.financesApiAxios.delete(`/v1/${this.userId}/expenses`, { data: { createDate }, ...this.getRequestConf() })
    );
  }

  async getUserPayIns(dateRange?: [StringOrNumber, StringOrNumber]) {
    let queryParams = '';
    if (dateRange?.length === 2) {
      queryParams = `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
    }

    this.preRequestVerify();
    return await axiosRequestHandler<UserPayIn[]>(
      () => this.financesApiAxios.get(`/v1/${this.userId}/pay-in${queryParams}`, this.getRequestConf())
    );
  }

  async createPayIn(payIn: UserPayIn) {
    this.preRequestVerify();
    return await axiosRequestHandler<void>(
      () => this.financesApiAxios.post(`/v1/${this.userId}/pay-in`, payIn,  this.getRequestConf())
    );
  }

  async getUserBankAccounts() {
    this.preRequestVerify();
    return await axiosRequestHandler<UserBankAccount[]>(
      () => this.financesApiAxios.get(`/v1/${this.userId}/bank-accounts`,  this.getRequestConf())
    );
  }
  
  async deletePayIn(createDate: number) {
    this.preRequestVerify();
    return await axiosRequestHandler<void>(
      () => this.financesApiAxios.delete(`/v1/${this.userId}/pay-in`, { params: { createDate }, ...this.getRequestConf() })
    );
  }

  async getUserReminders() {
    this.preRequestVerify();
    return await axiosRequestHandler<UserReminder[]>(
      () => this.financesApiAxios.get(`/v1/${this.userId}/reminders`,  this.getRequestConf())
    );
  }
  async createUserReminder(reminder: CreateUserReminderRequestBody) {
    this.preRequestVerify();
    return await axiosRequestHandler<void>(
      () => this.financesApiAxios.post(`/v1/${this.userId}/reminders`, reminder, this.getRequestConf())
    );
  }

  async deleteUserReminder({reminderDate, reminder} : MutationComposedSKProps) {
    this.preRequestVerify();
    return await axiosRequestHandler<void>(
      () => this.financesApiAxios.delete(`/v1/${this.userId}/reminders`, { data: { reminderDate, reminder }, ...this.getRequestConf() })
    );
  }

  async rescheduleUserReminder({reminderDate, reminder}: MutationComposedSKProps) {
    this.preRequestVerify();
    return await axiosRequestHandler<void>(
      () => this.financesApiAxios.put(`/v1/${this.userId}/reminders`, { reminderDate, reminder }, this.getRequestConf())
    );
  }
}







