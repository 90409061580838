import DeleteIcon from '@mui/icons-material/Delete';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Container, LinearProgress, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { SxProps, Theme, alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutationDeleteUserReminder, useMutationRescheduleUserReminder, useQueryUserReminders } from '../hooks/user-reminder-hooks';
import { MutationComposedSKProps, UserReminder } from '../types/User';
import { DateFormatByPurpose } from '../types/global';
import { epochToLocalTzFormatted } from '../util/formatting';
import CreateReminderPopover from './CreateReminderPopover';


interface EnhancedTableToolbarProps {
    numSelected: number;
    selectedItems: MutationComposedSKProps[];
    deleteMutator: UseMutationResult<void, Error, MutationComposedSKProps, unknown>;
}

const reminderChipStyles: SxProps<Theme> = {
    RECURRING: { p: 1, backgroundColor: "purple", color: "white" },
    UPCOMMING: { p: 1, backgroundColor: "#006D77", color: "white" },
    COMPLETE: { p: 1, backgroundColor: "#006400", color: "white" },
    FORGOTTEN: { p: 1, backgroundColor: "#E29578", color: "white" },
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof UserReminder;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'reminder',
        numeric: false,
        disablePadding: true,
        label: 'reminderMessage',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'reminderActions',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof UserReminder) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { t } = useTranslation();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
        (property: keyof UserReminder) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow >
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const [showReminderDetails, setShowReminderDetails] = useState<boolean>(false);
    const { t } = useTranslation();
    const { numSelected, selectedItems, deleteMutator } = props;

    return (

        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} {t('selected')}
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 10 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title={t("deleteReminderButton")}>
                    <IconButton disabled={deleteMutator.isPending} color='secondary' size='small'
                        onClick={() => {
                            try {
                                selectedItems.forEach(reminderDate => {
                                    deleteMutator.mutateAsync(reminderDate)
                                        .then(r => {
                                            setShowReminderDetails(false);
                                        })
                                        .catch(console.log);
                                });

                            } catch (error) {
                                console.log('selectedReminder did not have a reminderDate so could not performe a DELETE.', showReminderDetails)
                                console.log(error)
                            }
                        }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        {/* <FilterListIcon /> */}
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

export type UserRemindersProps = {
    userReminders: UserReminder[];
};

function EnhancedTable(props: UserRemindersProps) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const useDeleteUserReminderMutation = useMutationDeleteUserReminder(queryClient);
    const useRescheduleReminderMutation = useMutationRescheduleUserReminder(queryClient);
    const [showReminderDetails, setShowReminderDetails] = useState<boolean>(false);
    const [showRescheduleDetails, setShowRescheduleDetails] = useState<boolean>(false)
    const { userReminders } = props;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof UserReminder>('reminderDate');
    const [selected, setSelected] = useState<MutationComposedSKProps[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof UserReminder) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = userReminders?.map((n) => {return {reminderDate: n.reminderDate, reminder: n.reminder}}) ?? [];
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, reminderObj: MutationComposedSKProps) => {
        const selectedIndex = selected.indexOf(reminderObj);
        let newSelected: MutationComposedSKProps[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, reminderObj);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: MutationComposedSKProps) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (userReminders ?? []).length) : 0;

    const visibleRows = () => {
        const sorted = stableSort((userReminders ?? []), getComparator(order, orderBy));
        const start = page * rowsPerPage;
        const end = page * rowsPerPage + rowsPerPage;
        return sorted.slice(start, end);
    };

    function changeStatusFromReminderDate(reminderDate: number) {
        const currentDate: number = Date.now();
        if (reminderDate < currentDate) {
            return "FORGOTTEN";
        }
        else {
            return "UPCOMMING";
        };

    };

    return (
        <Box sx={{ width: '100%' }}>
            {useDeleteUserReminderMutation.isPending && <LinearProgress color="secondary" />}
            {useRescheduleReminderMutation.isPending && <LinearProgress color="secondary" />}

            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar deleteMutator={useDeleteUserReminderMutation} numSelected={selected.length} selectedItems={selected} />
                <div>

                </div>
                <TableContainer sx={{ maxHeight: '300px' }}>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        size="small"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={(userReminders ?? []).length}
                        />
                        <TableBody >
                            {visibleRows().map((row, index) => {
                                const isItemSelected = isSelected({reminder: row.reminder, reminderDate: row.reminderDate});
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, {reminderDate: row.reminderDate, reminder: row.reminder})}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.reminderDate}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell
                                            width="5%"
                                            padding="checkbox">
                                            <Chip size='small'
                                                label={t(changeStatusFromReminderDate(row.reminderDate))}
                                                sx={{
                                                    ...(reminderChipStyles![changeStatusFromReminderDate(row.reminderDate) as (keyof typeof reminderChipStyles)] as any),
                                                    fontSize: '.6em'
                                                }} />
                                        </TableCell>
                                        <TableCell
                                            width="45%"
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{ wordWrap: 'break-word', p: 1, fontSize: '.7em' }}
                                        >
                                            {row.reminder}
                                            <br /><br />
                                            <span style={{ fontSize: '.8em', color: '#E29578', overflowX: "hidden" }}>
                                                {epochToLocalTzFormatted(row.reminderDate, DateFormatByPurpose.Reminder)} <br />
                                                ({epochToLocalTzFormatted(row.reminderDate, DateFormatByPurpose.Reminder, true)})
                                            </span>
                                        </TableCell>
                                        <TableCell width="50%" align="left">
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                <IconButton disabled={useRescheduleReminderMutation.isPending} size='small' color="primary" title={t('rescheduleReminder')}
                                                    onClick={() => {
                                                        if (row?.reminderDate) {
                                                            useRescheduleReminderMutation.mutateAsync({reminder: row.reminder, reminderDate: row.reminderDate})
                                                                .then(r => {
                                                                    setShowRescheduleDetails(false)
                                                                })
                                                                .catch(console.log);
                                                        } else {
                                                            console.log('selectedReminder did not have a reminderDate so could not perfome a RESCHEDULE', showRescheduleDetails)
                                                        }
                                                    }}>
                                                    <EventRepeatIcon />
                                                </IconButton>
                                                <IconButton disabled={useDeleteUserReminderMutation.isPending} size='small' color="secondary" title={t('reminderDone')}
                                                    onClick={() => {
                                                        if (row?.reminderDate) {
                                                            useDeleteUserReminderMutation.mutateAsync({reminder: row.reminder, reminderDate: row.reminderDate})
                                                                .then(r => {
                                                                    setShowReminderDetails(false)
                                                                })
                                                                .catch(console.log);
                                                        } else {
                                                            console.log('selectedReminder did not have a reminderDate so could not performe a DELETE.', showReminderDetails);
                                                        }
                                                    }}>
                                                    <EventAvailableIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }} >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer  >
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={(userReminders ?? []).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('remindersRowsPerPage')}
                />
            </Paper>
        </Box>
    );
}

export function RemindersTable() {
    const { t } = useTranslation();
    const { data: userReminders } = useQueryUserReminders();
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <Container maxWidth={false} sx={{ mt: '20px', mb: '20px' }} >
            <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}   >
                    <NotificationsActiveIcon color='primary' />&nbsp;
                    <Typography variant="h6"> {t('reminders')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionDetails>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', overflowX: "hidden" }}>
                            <CreateReminderPopover />

                            <EnhancedTable userReminders={userReminders ?? []} />
                        </div>
                        <div>
                        </div>
                    </AccordionDetails>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}